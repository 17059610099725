body, #root{
  @apply h-screen;
}

.App {
  @apply min-h-full;
  background-color: #f0f0f0;
  touch-action: manipulation;
}

h1 {
  @apply text-5xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5 {
  @apply text-lg;
}

button {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

small {
  @apply block leading-3;
}

a.link {
  @apply underline text-sky-700;
}

.MuiTabs-root {
  @apply overflow-visible #{!important};

  .MuiTabScrollButton-root {
    @apply w-8 #{!important};
  }

  .MuiTabs-scroller {
    @apply overflow-x-visible overflow-y-scroll #{!important};
  }

  .MuiTab-root {
    @apply px-4 #{!important};

    &:not(.Mui-selected) {
      @apply text-black #{!important};
    }

    &.Mui-selected {
      @apply text-blue #{!important};
    }
  }

  .MuiTabs-indicator {
    @apply bg-transparent h-1 flex justify-center transform -translate-y-2 #{!important};

    &:after {
      content: '';
      @apply block w-1 h-1 bg-blue rounded-full;
    }
  }
}

#bottom-bar-replace {
  &.visible {
    transition: opacity 0.25s ease-in-out, visibility 0s;
  }

  &.invisible {
    transition: opacity 0.25s ease-in-out, visibility 0s 0.3s;
  }
}

#bottom-bar-main {
  &.visible {
    transition: opacity 0.25s ease-in-out, visibility 0s;
  }

  &.invisible {
    transition: opacity 0.25s ease-in-out, visibility 0s 0.3s;
  }
}